import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getSubscriptionFromSearchParams } from "../utils/pricing-content";

export enum Plans {
  Individual = "Individual",
  Couple = "Couple",
}

export enum Steps {
  SELECT_SUBSCRIPTION,
  SELECT_PLAN,
  SELECT_PAYMENT_PLAN,
}

const useCurrentStep = () => {
  const { state, search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search) || "Default";
  const { showNewPricingFlow } = useFlags();

  const getStep = (subscription: string = "Default") =>
    ({
      [Plans.Couple]:
        showNewPricingFlow && subscription
          ? state?.step || Steps.SELECT_PLAN
          : Steps.SELECT_PAYMENT_PLAN,
      [Plans.Individual]: state?.step || Steps.SELECT_PLAN,
      Default: state?.step || Steps.SELECT_SUBSCRIPTION,
    }[subscription]);

  return getStep(subscription);
};

export default useCurrentStep;
