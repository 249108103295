import { useGetDesktopStaticContentQuery } from "../../../api/cms";
import { DescriptionList, Typography } from "../../../components";
import PlanList from "./PlanList";
import PricesList from "./PricesList";
import styles from "./styles.module.scss";
import TiersList from "./TiersList";
import MoneyBackBanner from "../../../components/MoneyBackBanner";
import Title from "../../../components/Title";
import useCurrentStep, { Steps } from "../../../hooks/useCurrentStep";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getMatchingFromSearchParams, getSubscriptionFromSearchParams } from "../../../utils/pricing-content";
import PlansList from "./PlansList";

const SectionContent = () => {
  const { data: content } = useGetDesktopStaticContentQuery();
  const currentStep = useCurrentStep();
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const { showNewPricingFlow, matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);

  switch (currentStep) {
    case Steps.SELECT_PLAN:
      return (
        <div className={styles["tier-select_wrapper"]}>
          <Title />
          <MoneyBackBanner />
          <TiersList />
        </div>
      );
    case Steps.SELECT_PAYMENT_PLAN:
      return (
        <div className={styles.step_2}>
          <Typography variant="large-header">
            {(showNewPricingFlow && subscription) || (matchingPricing && matching)
              ? "Choose your plan"
              : content?.step3_title}
          </Typography>
          <MoneyBackBanner />
          <PricesList />
          <DescriptionList />
        </div>
      );
    default:
      if (!matching) {
        return (
          <div className={styles.initial_step}>
            <Typography variant="large-header">
              Are you flying solo or doing this with your partner?
            </Typography>
            <PlanList />
          </div>
        );
      } else {
        return (
          <div className={styles.step_2}>
            <Title />
            <MoneyBackBanner />
            <PlansList />
          </div>
        );
      }
  }
};

export default SectionContent;
