import React, { useEffect } from "react";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import DesktopLayout from "../Main/DesktopLayout";
import MobileLayout from "./MobileLayout";
import { NEW_ROUTES } from "../../router";
import { useLocation, useNavigate } from "react-router-dom";
import { Steps } from "../../hooks/useCurrentStep";
import {getMatchingFromSearchParams} from "../../utils/pricing-content";
import {useFlags} from "launchdarkly-react-client-sdk";

const PaymentPlan = () => {
  const isDesktopMode = useIsDesktopMode();
  const {search, state } = useLocation();
  const matching = getMatchingFromSearchParams(search);
  const { matchingPricing } = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.step !== Steps.SELECT_PAYMENT_PLAN && !matching && !matchingPricing) {
      navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
        state: {
          ...state,
          step: Steps.SELECT_PAYMENT_PLAN,
        },
      });
    }
  }, [navigate, state, matchingPricing, matching]);
  return isDesktopMode ? <DesktopLayout /> : <MobileLayout />;
};

export default PaymentPlan;
