import styles from "./styles.module.scss";
import loaderAnimation from "./loader_animation.json";
import Lottie from "lottie-react";
import Typography from "../Typography";

const Loading = ({ text }: { text?: string }) => (
  <div className={styles.wrapper}>
    <Lottie animationData={loaderAnimation} loop={true} />
    {text && <Typography>{text}</Typography>}
  </div>
);

export default Loading;
