import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetDesktopStaticContentQuery,
  useGetPricesQuery,
} from "../../../api/cms";
import Stepper, { STEP_COUNT } from "../../../components/Stepper";
import useCurrentTier from "../../../hooks/useCurrentTier";
import { NEW_ROUTES, ROUTES } from "../../../router";
import SectionContent from "./SectionContent";
import styles from "./styles.module.scss";
import useCurrentStep from "../../../hooks/useCurrentStep";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getMatchingFromSearchParams,
  getSubscriptionFromSearchParams,
} from "../../../utils/pricing-content";
import {
  getNewBillingCycleFromQuery,
} from "../../../utils/getCustomerData";
import { useGetBackendPlansQuery } from "../../../api/management";
import { findMatchedPlan } from "../../../utils/getMatchedPlan";
import useRedirect from "../../../hooks/useRedirect";
import Loading from "../../../components/Loading";

const MainSection = () => {
  const { data: content } = useGetDesktopStaticContentQuery();
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const currentTier = useCurrentTier();
  useGetPricesQuery(currentTier?.id, { skip: !currentTier });
  const { redirect: newRedirect, isLoading } = useRedirect();
  const step = useCurrentStep();
  const subscription = getSubscriptionFromSearchParams(search);
  const { showNewPricingFlow, matchingPricing, newBillingCycle } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const billingCycle = getNewBillingCycleFromQuery();
  const { data: backendPlans } = useGetBackendPlansQuery();
  const matchingPlan = findMatchedPlan(subscription, state, backendPlans);
  const Routes = {
    Individual: NEW_ROUTES.SELECT_INDIVIDUAL_PLAN,
    Couple: NEW_ROUTES.SELECT_COUPLE_PLAN,
  };

  const handleContinueClick = async () => {
    if (step + 1 >= STEP_COUNT) {
      const payload = {
        tier: currentTier?.tier_type,
        price:
          newBillingCycle && matchingPlan && billingCycle
            ? matchingPlan.stripe_price_id
            : state?.price || "",
        postgres_plan_id:
          newBillingCycle && matchingPlan && billingCycle
            ? matchingPlan.uuid
            : state?.postgres_plan_id || 0,
      };
      await newRedirect(payload);
    } else {
      if (matchingPricing && matching) {
        navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
          state: {
            tier: state?.plan_match.default_tier,
            plan: state?.plan_match,
            step: 2,
          },
        });
      } else {
        navigate(
          showNewPricingFlow && subscription
            ? NEW_ROUTES.SELECT_PAYMENT_PLAN
            : ROUTES.HOME,
          { state: { ...state, step: step + 1 } },
        );
      }
    }
  };

  const showBackButton =
    (subscription && showNewPricingFlow && step === 2) ||
    (showNewPricingFlow && !subscription) ||
    (!showNewPricingFlow && subscription) ||
    !showNewPricingFlow ||
    !subscription;

  const handleBackClick = () => {
    if (matching && matchingPricing) {
      navigate(NEW_ROUTES.PLANS, {
        state: { ...state, step: 0 },
      });
    } else {
      navigate(subscription ? Routes[subscription] : ROUTES.HOME, {
        state: { ...state, step: step - 1 },
      });
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div className={styles.main_section}>
      {(showNewPricingFlow && subscription) || (matchingPricing && matching) ? (
        <></>
      ) : (
        <Stepper />
      )}
      <SectionContent />
      <div className={styles.buttons_wrapper}>
        {showBackButton && (
          <button
            onClick={handleBackClick}
            disabled={!step}
            className={`${styles.btn} ${styles.back_button} ${
              (subscription && showNewPricingFlow) ||
              (matchingPricing && matching)
                ? styles.new_back_button
                : ""
            }`}
            id="stepper_back_button"
          >
            {content?.back_button}
          </button>
        )}
        <button
          className={`${styles.btn} ${
            (subscription && showNewPricingFlow) ||
            (matchingPricing && matching)
              ? styles.new_continue_button
              : styles.continue_button
          }`}
          onClick={handleContinueClick}
          id="stepper_continue_button"
        >
          {content?.button}
        </button>
      </div>
    </div>
  );
};

export default MainSection;
