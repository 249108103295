import React, { useEffect, useState } from "react";
import BackButton from "../../../components/BackButton";
import { Typography } from "../../../components";
import styles from "./styles.module.scss";
import PriceDetails from "./PriceDetails";
import ReviewCard from "../../../components/ReviewCard";
import useIsDesktopMode from "../../../hooks/useIsDesktopMode";
import Arrow from "../../../assets/icons/Arrow";
import PromotionCode from "./PromotionCode";
import useCurrentPlan from "../../../hooks/useCurrentPlan";
import MobileViewPopup from "./MobileView";
import {
  getMatchingFromSearchParams,
  getPlanTitle,
} from "../../../utils/pricing-content";
import { useLocation } from "react-router-dom";
import { useGetReviewsQuery } from "../../../api/cms";
import { Review } from "../../../api/types";
import { useFlags } from "launchdarkly-react-client-sdk";

const CheckoutDetails = () => {
  const isDesktopMode = useIsDesktopMode();
  const [isDetailsClicked, setIsDetailsClicked] = useState(false);
  const [review, setReview] = useState<Review>();
  const plan = useCurrentPlan();
  const { state, search } = useLocation();
  const { data: reviews } = useGetReviewsQuery();
  const selectedReviewId = 1;
  const { matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const planTitle = state
    ? getPlanTitle(state.months, plan.name, state.frequency)
    : "Matching session";
  const subscribeText = !(matching && matchingPricing) ? "Subscribe to " : "";
  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode");

  useEffect(() => {
    if (reviews) {
      setReview(reviews.find((review) => review.id === selectedReviewId));
    }
  }, [reviews]);

  const onDetailsClick = () => {
    setIsDetailsClicked((prevState) => !prevState);
  };

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <BackButton />
          {!isDesktopMode && (
            <div className={styles.mobileDetails} onClick={onDetailsClick}>
              <Typography variant="subtitle">Details</Typography>
              <Arrow transform="rotate(90)" />
            </div>
          )}
        </div>
        <div className={styles.wrapper}>
          <Typography
            variant="subtitle"
            style={{ fontWeight: 600, textAlign: "center" }}
          >
            {subscribeText}Ritual - {planTitle}
          </Typography>
        </div>

        <PriceDetails showPrice={false} />

        {!isDesktopMode && !mode && <PromotionCode />}

        {isDesktopMode && review && (
          <ReviewCard
            review={review?.Description}
            rate={review?.rating}
            name={review?.Author}
          />
        )}
        {isDetailsClicked && <MobileViewPopup onClose={onDetailsClick} />}
      </div>
    </div>
  );
};

export default CheckoutDetails;
