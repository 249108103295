import Tiers from "./Tiers";
import { Switch } from "../../components";
import DesktopLayout from "./DesktopLayout";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import Title from "../../components/Title";
import MoneyBackBanner from "../../components/MoneyBackBanner";

const Main = () => {
  const isDesktopMode = useIsDesktopMode();

  return isDesktopMode ? (
    <DesktopLayout />
  ) : (
    <>
      <Switch />
      <Title variant="header" />
      <MoneyBackBanner />
      <Tiers />
    </>
  );
};

export default Main;
