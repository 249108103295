import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  id?: string;
  style?: { [key: string]: string };
}

const Button = ({ children, onClick, id, style }: ButtonProps) => {
  return (
    <button id={id} className={styles.btn} onClick={onClick} style={style}>
      {children}
    </button>
  );
};

export default Button;
