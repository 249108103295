import RadioCheck from "../../assets/icons/RadioCheck";
import styles from "./styles.module.scss";
import useCurrentStep from "../../hooks/useCurrentStep";

export const STEP_COUNT = 3;

const Stepper = () => {
  const currentStep = useCurrentStep();

  return (
    <div className={styles.stepper}>
      {new Array(STEP_COUNT).fill(0).map((_, i) => (
        <div
          key={i}
          className={`${styles.step} ${
            i === currentStep ? styles.step_active : ""
          }`}
        >
          {i >= currentStep ? (
            <div>
              <div />
            </div>
          ) : (
            <RadioCheck />
          )}
          <p className={`${i > currentStep ? styles.next_step : ""}`}>
            Step {i + 1}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
