import React from "react";
import AccountCreationError from "../../assets/images/img_error_nomember.png";
import styles from "./styles.module.scss";
import { Typography } from "../../components";
import { useLocation } from "react-router-dom";
import {
  getPlanTitle,
  REACT_APP_SUPPORT_EMAIL,
} from "../../utils/pricing-content";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import OurRitualLogo from "../../assets/icons/OurRitualLogo";

const MemberCreationError = () => {
  const { state } = useLocation();
  const plan = useCurrentPlan();

  return (
    <div className={styles.wrapper}>
      <OurRitualLogo />
      <div className={styles.content}>
        <img src={AccountCreationError} alt="account-creation-error" />
        <div className={styles.desktop_title}>
          Your account is <span>almost ready</span>
        </div>
        <Typography variant="subtitle">
          You’ve successfully purchased the{" "}
          {state
            ? getPlanTitle(state?.months, plan?.name, state?.frequency)
            : "Matching Session"}
          . However, we encountered an issue while creating your account. Please
          contact Support to complete your account setup and get access to our
          Ritual app.
        </Typography>
        <a className={styles.button} href={REACT_APP_SUPPORT_EMAIL}>
          Contact Support
        </a>
      </div>
    </div>
  );
};

export default MemberCreationError;
