import React from "react";
import RepackagingPriceCard from "./RepackagingPriceCard";

export interface PriceCardProps {
  title: string;
  priceId: string;
  cardLabel: string;
  months: number;
  initialPrice?: number;
  selected: boolean;
  mostExpensivePrice: number;
}

const Wrapper = (props: PriceCardProps) => {
  return <RepackagingPriceCard {...props} />;
};

export default Wrapper;
