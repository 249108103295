import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetPricesQuery } from "../../../api/cms";
import {
  useGetPriceQuery,
  useGetMostExpensivePriceQuery,
} from "../../../api/stripe";
import { Price } from "../../../api/types";
import { DescriptionList } from "../../../components";
import Button from "../../../components/Button";
import PriceCard from "../../../components/PriceCard";
import useCurrentPlan from "../../../hooks/useCurrentPlan";
import styles from "../../Main/Tiers/styles.module.scss";
import NewButton from "../../../components-new/Button";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getMatchingFromSearchParams,
  getSubscriptionFromSearchParams,
} from "../../../utils/pricing-content";
import { getNewBillingCycleFromQuery } from "../../../utils/getCustomerData";
import { useGetBackendPlansQuery } from "../../../api/management";
import { findMatchedPlan } from "../../../utils/getMatchedPlan";
import useCurrentTier from "../../../hooks/useCurrentTier";
import useRedirect from "../../../hooks/useRedirect";

const PricesList = () => {
  const tier = useCurrentTier();
  const { data: prices } = useGetPricesQuery(tier?.id, { skip: !tier });
  const { data: initialPrice } = useGetPriceQuery(tier?.initial_price);
  const plan = useCurrentPlan();
  const [selected, setSelected] = useState<Price | null>(null);
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const { showNewPricingFlow, matchingPricing, newBillingCycle } = useFlags();
  const billingCycle = getNewBillingCycleFromQuery();
  const matching = getMatchingFromSearchParams(search);
  const { data: backendPlans } = useGetBackendPlansQuery();
  const matchingPlan = findMatchedPlan(subscription, selected, backendPlans);
  const { redirect: newRedirect, isLoading } = useRedirect();

  const mostExpensivePrice = useGetMostExpensivePriceQuery(
    prices?.map((item) => item.stripe_id) || [],
  );

  useEffect(() => {
    if (!prices) return;

    setSelected(prices.length >= 2 ? prices[1] : prices[0]);
  }, [prices]);

  const redirectToPayment = async () => {
    const payload = {
      price:
        newBillingCycle && matchingPlan && billingCycle
          ? matchingPlan.stripe_price_id
          : selected?.stripe_id || "",
      tier: tier?.tier_type,
      months: selected?.months || 1,
      postgres_plan_id:
        newBillingCycle && matchingPlan && billingCycle
          ? matchingPlan.uuid
          : selected?.postgres_plan_id || 0,
    };
    await newRedirect(payload);
  };

  if (!tier) return <></>;

  return (
    <>
      <div className={`${styles.wrapper} ${styles.wrapper_prices}`}>
        {prices?.map((item) => (
          <div
            id={`price_item_mobile-${item.id}`}
            onClick={() => setSelected(item)}
            key={item.id}
          >
            <PriceCard
              title={item.title}
              priceId={item.stripe_id}
              cardLabel={item.label}
              months={item.months}
              initialPrice={initialPrice || 0}
              selected={item.id === selected?.id}
              mostExpensivePrice={mostExpensivePrice}
            />
          </div>
        ))}
      </div>
      <DescriptionList />
      {selected &&
        ((showNewPricingFlow && subscription) ||
        (matching && matchingPricing) ? (
          <NewButton
            id="new_redirect_to_payment_btn"
            onClick={redirectToPayment}
          >
            {!isLoading ? "Continue" : "Loading..."}
          </NewButton>
        ) : (
          <Button id="redirect_to_payment_btn" onClick={redirectToPayment}>
            {plan.button}
          </Button>
        ))}
    </>
  );
};

export default PricesList;
