import React from "react";
import styles from "./styles.module.scss";
import Info from "../../assets/icons/Info";
import { Typography } from "../index";

const ErrorMessage = ({ error }: { error: string }) => {
  const color = "#D60D0D";
  return (
    <div className={styles.errorMessage}>
      <Info color={color} />
      <Typography variant="subtitle" style={{ color, width: "90%" }}>
        {error}
      </Typography>
    </div>
  );
};

export default ErrorMessage;
