import React from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { NEW_PAGE_ROUTES, newRoutes } from "../router";
import { DesktopHeader, UnlockGrowthBanner } from "../components";
import styles from "../styles.module.scss";
import useIsDesktopMode from "../hooks/useIsDesktopMode";

const NewPricingPage = () => {
  const isDesktopMode = useIsDesktopMode();
  const router = useRoutes(newRoutes);
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== NEW_PAGE_ROUTES.CHECKOUT &&
        pathname !== NEW_PAGE_ROUTES.MEMBER_CREATION_ERROR &&
        pathname !== NEW_PAGE_ROUTES.UPDATE_PAYMENT_DETAILS &&
        (isDesktopMode ? <DesktopHeader /> : <UnlockGrowthBanner />)}
      <div className={!isDesktopMode ? styles.pricing_wrapper : ""}>
        <div
          className={styles.wrapper}
          style={{
            ...((pathname === NEW_PAGE_ROUTES.CHECKOUT ||
              pathname === NEW_PAGE_ROUTES.UPDATE_PAYMENT_DETAILS) && {
              padding: 0,
              maxWidth: "100%",
            }),
          }}
        >
          {router}
        </div>
      </div>
    </>
  );
};

export default NewPricingPage;
