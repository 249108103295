import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { store } from "./store";
import "./styles/index.scss";
import "./assets/fonts/index.css";
import { CouponProvider } from "./contexts/CouponContext";

import * as Sentry from "@sentry/react";

if (!window.location.origin.includes("localhost")) {
  Sentry.init({
    dsn: "https://748dc09481d5068c27a8051120b1a6a9@o4507695388098560.ingest.us.sentry.io/4507736094408704",
    integrations: [],
    environment: window.location.origin.includes("dev") ? "dev" : "prod",
  });

  window.Sentry = Sentry;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CouponProvider>
        <Router>
          <App />
        </Router>
      </CouponProvider>
    </Provider>
  </React.StrictMode>
);
