import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#F76666"
      d="M19.733 14.694c0-2.547.395-4.735 1.185-6.563.789-1.894 1.809-3.429 3.058-4.604 1.25-1.241 2.664-2.123 4.243-2.645C29.863.294 31.475 0 33.054 0v3.429c-1.71 0-3.322.587-4.835 1.763-1.447 1.11-2.302 2.645-2.565 4.604.197-.065.427-.13.69-.196.198-.065.428-.13.69-.196.33-.065.692-.098 1.086-.098 1.974 0 3.618.751 4.934 2.253 1.315 1.437 1.973 3.135 1.973 5.094 0 1.96-.69 3.69-2.072 5.192C31.639 23.282 29.863 24 27.627 24c-2.5 0-4.44-.914-5.821-2.743-1.382-1.894-2.073-4.082-2.073-6.563ZM0 14.694c0-2.547.395-4.735 1.184-6.563.79-1.894 1.809-3.429 3.059-4.604C5.493 2.286 6.907 1.404 8.485.882 10.13.294 11.742 0 13.32 0v3.429c-1.71 0-3.322.587-4.835 1.763-1.447 1.11-2.302 2.645-2.565 4.604.197-.065.428-.13.69-.196.198-.065.428-.13.691-.196.33-.065.691-.098 1.086-.098 1.973 0 3.618.751 4.933 2.253 1.316 1.437 1.973 3.135 1.973 5.094 0 1.96-.69 3.69-2.071 5.192C11.905 23.282 10.13 24 7.893 24c-2.5 0-4.44-.914-5.821-2.743C.691 19.363 0 17.175 0 14.694Z"
    />
  </svg>
);
export default SvgComponent;
