import { useLocation, useNavigate } from "react-router-dom";
import { useGetTiersQuery } from "../../../api/cms";
import { TierCardDesktop } from "../../../components";
import useCurrentPlan from "../../../hooks/useCurrentPlan";
import useCurrentTier from "../../../hooks/useCurrentTier";
import { NEW_ROUTES, ROUTES } from "../../../router";
import styles from "./styles.module.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getSubscriptionFromSearchParams } from "../../../utils/pricing-content";

const TiersList = () => {
  const plan = useCurrentPlan();
  const { data: tiers } = useGetTiersQuery(String(plan?.id));
  const tier = useCurrentTier();
  const navigate = useNavigate();
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const { showNewPricingFlow } = useFlags();

  const Routes = {
    Individual: NEW_ROUTES.SELECT_INDIVIDUAL_PLAN,
    Couple: NEW_ROUTES.SELECT_COUPLE_PLAN,
  };

  return (
    <div className={styles.tier_wrapper} style={{ maxWidth: "initial" }}>
      {tiers?.map((item) => (
        <div
          key={item.id}
          id={`tier_item-${item.id}`}
          onClick={() =>
            navigate(
              subscription && showNewPricingFlow
                ? Routes[subscription]
                : ROUTES.HOME,
              { state: { tier: item, plan, step: 1 } }
            )
          }
        >
          <TierCardDesktop
            initialPrice={String(item.initial_price)}
            title={item.Name}
            subtitle={item.description}
            selected={tier.id === item.id}
            image={item.image}
            length={tiers.length}
            features={item.features_list}
          />
        </div>
      ))}
    </div>
  );
};

export default TiersList;
