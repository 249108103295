import React from "react";
import styles from "./styles.module.scss";
import { Typography } from "../../components";
import TiersList from "../Main/DesktopLayout/TiersList";
import Button from "../../components-new/Button";
import { NEW_ROUTES } from "../../router";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import useCurrentTier from "../../hooks/useCurrentTier";
import { useLocation, useNavigate } from "react-router-dom";
import MoneyBackBanner from "../../components/MoneyBackBanner";

const MobileLayout = () => {
  const plan = useCurrentPlan();
  const tier = useCurrentTier();
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.plan_wrapper}>
      <Typography variant="header" style={{ margin: "10px 0 20px" }}>
        Here’s your recommended plan
      </Typography>
      <TiersList />
      <MoneyBackBanner />

      <Button
        onClick={() =>
          navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
            state: {
              tier: state?.tier || tier,
              plan: state?.plan || plan,
            },
          })
        }
      >
        Continue
      </Button>
    </div>
  );
};

export default MobileLayout;
