import { useLocation, useNavigate } from "react-router-dom";
import { useGetPlansQuery } from "../../../api/cms";
import useCurrentPlan from "../../../hooks/useCurrentPlan";
import { NEW_ROUTES } from "../../../router";
import styles from "./styles.module.scss";
import PlanCardDesktop from "../../../components/PlanCardDesktop";

const PlansList = () => {
  const plan = useCurrentPlan();
  const { data: plans } = useGetPlansQuery();

  const navigate = useNavigate();
  const { state } = useLocation();
  const new_plan = state?.plan_match ? state.plan_match : plan;

  return (
    <div className={styles.tier_wrapper} style={{ maxWidth: "initial" }}>
      {plans?.map((item) => (
        <div
          key={item.id}
          id={`tier_item-${item.id}`}
          onClick={() =>
            navigate(NEW_ROUTES.PLANS, {
              state: { tier: item, new_plan, step: 0, plan_match: item },
            })
          }
        >
          <PlanCardDesktop
            initialPrice={String(item.default_tier.initial_price)}
            title={item.name}
            subtitle={item.default_tier.description}
            selected={new_plan?.default_tier?.id === item?.default_tier?.id}
            image={item.image}
            length={plans.length}
            features={item.default_tier.features_list}
          />
        </div>
      ))}
    </div>
  );
};

export default PlansList;
