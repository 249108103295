import { getCookieValue } from "./getCookieValue";

export const RITUAL_MATCHING = `${process.env.REACT_APP_RITUAL_DOMAIN}/ritual-take-the-quiz`;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
export const REACT_APP_WEBAPP_URL = process.env.REACT_APP_WEBAPP_DOMAIN;

export const redirect = (params: {
  stripeId: string;
  tier_type: string;
  checkoutUrl?: string;
  featureFlag: boolean;
  initialPriceAmount?: number;
  postgres_plan_id: number;
  percent_off?: number;
}) => {
  const {
    stripeId,
    tier_type,
    featureFlag,
    checkoutUrl,
    initialPriceAmount,
    postgres_plan_id,
    percent_off,
  } = params;
  const funnelStart =
    new URLSearchParams(window.location.search).get("funnel_start") ||
    "pricing";
  const url = getUrl(
    stripeId,
    tier_type,
    postgres_plan_id,
    initialPriceAmount,
    percent_off
  );

  if (window.self !== window.top) {
    const final_link =
      (funnelStart === "pricing" ? RITUAL_MATCHING : PAYMENT_URL) + url;
    window.parent.postMessage({ type: "REDIRECT", final_link }, "*");
  } else {
    if (funnelStart === "tier_change")
      return (window.location.href = REACT_APP_WEBAPP_URL || "");
    if (!featureFlag || funnelStart === "pricing") {
      return (window.location.href = (funnelStart === "pricing" ? RITUAL_MATCHING : PAYMENT_URL) + url);
    }
    if (checkoutUrl) return (window.location.href = checkoutUrl);
  }
};

export const getUrl = (
  stripeId: string,
  tier_type: string,
  postgres_plan_id: number,
  initialPriceAmount?: number,
  percent_off?: number
) => {
  const urlSearch = window.location.search;
  const params = new URLSearchParams(urlSearch);
  let funnelStart = params.get("funnel_start");
  const funnelid = params.get("funnelid");
  const vwoUuid = getCookieValue("_vwo_uuid");
  const pricingQueryParams = `vwo_uuid=${vwoUuid}&price=${stripeId}&postgres_plan_id=${postgres_plan_id}&tier_type=${tier_type}&funnelid=${
    funnelid || "tiers-v1"
  }&initial_amount=${initialPriceAmount || 1}${
    percent_off ? "&percent_off=" + percent_off : ""
  }`;
  let url = (urlSearch ? urlSearch + "&" : "?") + pricingQueryParams;
  if (!funnelStart) {
    funnelStart = "pricing";
    url += `&funnel_start=${funnelStart}`;
  }

  return url;
};
