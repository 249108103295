import { useLocation, useNavigate } from "react-router-dom";
import { getEmailFromSearchParams } from "../utils/pricing-content";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getPriceFromQuery } from "../utils/getCustomerData";
import { NEW_ROUTES } from "../router";
import { useLazyGetNeedToUpdatePaymentDetailsQuery } from "../api/management";
import useCurrentTier from "./useCurrentTier";
import {
  getUrl,
  REACT_APP_WEBAPP_URL,
  RITUAL_MATCHING,
} from "../utils/redirect";
import { useContext, useState } from "react";
import { CouponContext } from "../contexts/CouponContext";
import { useGetPriceQuery } from "../api/stripe";

const useRedirect = () => {
  const { state } = useLocation();
  const currentTier = useCurrentTier();
  const { isUpdatePaymentDetailsVisible } = useFlags();
  const [getNeedToUpdatePaymentDetails] =
    useLazyGetNeedToUpdatePaymentDetailsQuery();
  const navigate = useNavigate();
  const funnelStart =
    new URLSearchParams(window.location.search).get("funnel_start") ||
    "pricing";
  const { coupon } = useContext(CouponContext);
  const { originalPrice } = useGetPriceQuery(state?.price || "");
  const [isLoading, setIsLoading] = useState(false);

  const redirect = async (payload: {
    price?: string;
    tier?: string;
    months?: number;
    postgres_plan_id?: number;
  }) => {
    setIsLoading(true);
    const url = getUrl(
      state?.price,
      currentTier?.tier_type,
      state?.postgres_plan_id,
      originalPrice,
      coupon?.percentOff,
    );
    if (funnelStart === "tier_change") {
      setIsLoading(false);
      return (window.location.href = REACT_APP_WEBAPP_URL || "");
    }
    if (funnelStart === "pricing") {
      setIsLoading(false);
      return (window.location.href = RITUAL_MATCHING + url);
    }
    // TOD0: remove after release
    if (isUpdatePaymentDetailsVisible) {
      const email = getEmailFromSearchParams();
      const price = getPriceFromQuery();
      try {
        const isUpdatePaymentDetailsRequired =
          await getNeedToUpdatePaymentDetails({
            email: email.replaceAll("+", "%2B"),
            price: price || state?.price,
          }).unwrap();

        if (isUpdatePaymentDetailsRequired) {
          setIsLoading(false);
          navigate(NEW_ROUTES.UPDATE_PAYMENT_DETAILS, {
            state: {
              ...state,
              ...payload,
              frequency: currentTier?.frequency,
            },
          });
        } else {
          setIsLoading(false);
          navigate(NEW_ROUTES.CHECKOUT, {
            state: {
              ...state,
              ...payload,
              frequency: currentTier?.frequency,
            },
          });
        }
      } catch (e: any) {
        setIsLoading(false);
        navigate(NEW_ROUTES.SELECT_INDIVIDUAL_PLAN);
      }
    } else {
      setIsLoading(false);
      navigate(NEW_ROUTES.CHECKOUT, {
        state: {
          ...state,
          ...payload,
          frequency: currentTier?.frequency,
        },
      });
    }
  };

  return { redirect, isLoading };
};

export default useRedirect;
