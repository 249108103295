import React from "react";
import styles from "../styles.module.scss";
import BackButton from "../../../../components/BackButton";
import { Typography } from "../../../../components";
import PriceDetails from "../PriceDetails";
import CloseIcon from "../../../../assets/icons/Close";

const MobileViewPopup = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className={styles.active} onClick={onClose}>
      <div
        className={styles.paymentMobileDetailsWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.header}>
          <BackButton />
          {
            <div className={styles.mobileDetails} onClick={onClose}>
              <Typography variant="subtitle">Close</Typography>
              <CloseIcon color="#575961" />
            </div>
          }
        </div>

        <PriceDetails showPrice={true} />
      </div>
    </div>
  );
};

export default MobileViewPopup;
