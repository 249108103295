import { Header, DesktopHeader } from "../components";
import { NEW_PAGE_ROUTES, routes } from "../router";
import { useLocation, useRoutes } from "react-router-dom";
import { useGetDesktopStaticContentQuery, useGetPlansQuery } from "../api/cms";
import styles from "../styles.module.scss";
import { useEffect } from "react";
import useIsDesktopMode from "../hooks/useIsDesktopMode";
import { useGetPricesQuery } from "../api/stripe";
import Loading from "../components/Loading";
import { useGetCouponResolver } from "../api/resolvers";

function App() {
  const router = useRoutes(routes);
  const { data } = useGetPlansQuery();
  const isDesktopMode = useIsDesktopMode();
  const { isFetching } = useGetDesktopStaticContentQuery(undefined, {
    skip: !isDesktopMode,
  });
  const { pathname } = useLocation();
  useGetPricesQuery(); // prefetch
  useGetCouponResolver();

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  if (!data || isFetching) return <Loading />;

  return (
    <>
      {pathname !== NEW_PAGE_ROUTES.CHECKOUT &&
        pathname !== NEW_PAGE_ROUTES.MEMBER_CREATION_ERROR &&
        (isDesktopMode ? <DesktopHeader /> : <Header />)}
      <div
        className={styles.wrapper}
        style={{
          ...(pathname === NEW_PAGE_ROUTES.CHECKOUT && {
            padding: 0,
            maxWidth: "100%",
          }),
        }}
      >
        {router}
      </div>
    </>
  );
}

export default App;
