import { useGetDesktopStaticContentQuery } from "../../api/cms";
import CheckMark from "../../assets/icons/CheckMark";
import CircledCheckMark from "../../assets/icons/CircledCheckMark";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import Typography from "../Typography";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import {getMatchingFromSearchParams, getSubscriptionFromSearchParams} from "../../utils/pricing-content";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import Header from "../../components-new/Header";

const UnlockGrowthBanner = () => {
  const currentPlan = useCurrentPlan();
  const items = currentPlan?.items?.split("\n");
  const { data: content } = useGetDesktopStaticContentQuery();
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const { showNewPricingFlow } = useFlags();
  const isDesktopMode = useIsDesktopMode();
  const matching = getMatchingFromSearchParams(search);

  return (
    <div
      className={`${styles.wrapper} ${
          (subscription && showNewPricingFlow) || matching ? styles.new_pricing : ""
      }`}
    >
      {!isDesktopMode && <Header />}
      {(showNewPricingFlow && subscription) || matching ? (
        <div className={`${styles.footer_title} ${styles.new_pricing_text}`}>
          <h1>Unlock relationship growth with us</h1>
          {isDesktopMode && <p>Here’s what you’ll get:</p>}
        </div>
      ) : (
        <div
          className={`${styles.footer_title}`}
          dangerouslySetInnerHTML={{ __html: content?.footer || "" }}
        />
      )}
      <ul className={styles.list}>
        {items?.map((item) => (
          <li key={item}>
            {(showNewPricingFlow && subscription) || matching ? (
              <CircledCheckMark />
            ) : (
              <CheckMark />
            )}
            <Typography
              style={{
                ...(((showNewPricingFlow && subscription) || matching) && { color: "#1F2222" }),
              }}
            >
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UnlockGrowthBanner;
