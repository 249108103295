import { useLocation } from "react-router-dom";
import { useGetPlansQuery } from "../api/cms";
import { Plan } from "../api/types";
import { getSubscriptionFromSearchParams } from "../utils/pricing-content";

export enum Subscriptions {
  Couple = "Couple",
  Individual = "Solo",
  Matching = "Matching",
}

function useCurrentPlan() {
  const location = useLocation();
  const state = location.state as { plan: Plan };
  const subscription = getSubscriptionFromSearchParams(location.search);

  const { data } = useGetPlansQuery();

  return (
    (subscription &&
      data?.find((item) => item.name === Subscriptions[subscription])) ||
    state?.plan ||
    data?.[0]
  );
}

export default useCurrentPlan;
