import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "../../components";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import { ROUTES } from "../../router";
import PricesList from "./PricesList";
import MoneyBackBanner from "../../components/MoneyBackBanner";

const Prices = () => {
  const plan = useCurrentPlan();
  const location = useLocation();
  const isDesktopMode = useIsDesktopMode();
  const navigate = useNavigate();

  const tier = location.state?.tier;

  useEffect(() => {
    if (isDesktopMode) navigate(ROUTES.HOME);
  }, [isDesktopMode, navigate]);

  if (!tier) return <></>;

  return (
    <>
      <Typography variant="header">{plan.second_title}</Typography>
      <MoneyBackBanner />
      <PricesList />
    </>
  );
};

export default Prices;
