import BackIcon from "../../assets/icons/Back";

const BackButton = ({ className }: { className?: string }) => {
  const handleClick = () => {
    window.history.back();
  };

  return (
    <BackIcon
      id="site_back_button"
      className={className}
      onClick={handleClick}
      style={{ position: "absolute", left: "24px", cursor: "pointer" }}
    />
  );
};

export default BackButton;
