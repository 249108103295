import React, { useEffect, useState } from "react";
import Typography from "../Typography";
import styles from "./styles.module.scss";
import ModalClose from "../../assets/icons/ModalClose";
import { AllPlansDescription } from "../../api/types";

interface DescriptionModalProps {
  selected: AllPlansDescription;
  onClose: () => void;
}

const DescriptionModal = ({ selected, onClose }: DescriptionModalProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div
      className={`${styles.modal_overlay} ${isVisible ? styles.visible : ""}`}
      onClick={handleClose}
    >
      <div
        className={styles.modal_content}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={styles.line}></div>
        <ModalClose onClick={handleClose} className={styles.modal_close} />
        <Typography variant="title" className={styles.modal_title}>
          {selected?.title}
        </Typography>
        <div
          className={
            isLoading ? styles.skeleton_img : styles["modal_images-wrapper"]
          }
        >
          <img
            key={selected.id}
            src={selected.contentImage}
            alt="content-preview"
            onLoad={handleImageLoaded}
            style={{
              display: isLoading ? "none" : "block",
              objectFit: "cover",
            }}
          />
        </div>
        <Typography variant="body2" className={styles.modal_text}>
          {selected?.description}
        </Typography>
      </div>
    </div>
  );
};

export default DescriptionModal;
