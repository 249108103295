import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useGetDesktopStaticContentQuery, useGetPricesQuery } from "../api/cms";
import Loading from "../components/Loading";
import useCurrentTier from "../hooks/useCurrentTier";
import { getUrl, PAYMENT_URL, redirect } from "../utils/redirect";
import axios from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetPriceQuery } from "../api/stripe";
import { useGetCouponResolver } from "../api/resolvers";

export const LAMBDA_MEMBER_TIER_CHANGED_URL =
  process.env.REACT_APP_LAMBDA_TIER_CHANGED_URL ||
  "https://kejyzbrhick636nco23uoleb5u0rfprr.lambda-url.us-east-1.on.aws/";

export const signature = process.env.REACT_APP_LAMBDA_TIER_SIGNATURE;

interface RedirectParams {
  price: string;
  tier: string;
  months: number;
  postgres_plan_id: number;
}

interface ContextValue {
  redirect: (params: RedirectParams) => void;
}

const LoadingContext = createContext<ContextValue>({} as ContextValue);

export const useLoading = () => useContext(LoadingContext);

const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<RedirectParams | null>(null);
  const { data: content } = useGetDesktopStaticContentQuery();
  const currentTier = useCurrentTier();
  const { data: prices } = useGetPricesQuery(currentTier?.id, {
    skip: !currentTier,
  });
  const [paymentUrls, setPaymentUrl] = useState<{ [key: string]: string }>({});
  const { rx1507PrefetchPaymentPagesFromClient } = useFlags();
  const funnelStart = new URLSearchParams(window.location.search).get(
    "funnel_start"
  );
  const { data: coupon } = useGetCouponResolver();

  const { originalPrice } = useGetPriceQuery(state?.price || "");

  useEffect(() => {
    if (!rx1507PrefetchPaymentPagesFromClient) return;

    if (!prices || !funnelStart || funnelStart === "pricing") return; // no need to prefetch if it will be redirected to matching

    prices.forEach(({ stripe_id, postgres_plan_id }) => {
      const url = getUrl(stripe_id, currentTier.tier_type, postgres_plan_id);
      if (paymentUrls[stripe_id]) return;
      axios
        .get(PAYMENT_URL + url)
        .then(({ data }) => {
          setPaymentUrl((prev: any) => ({ ...prev, [stripe_id]: data }));
        })
        .catch();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices]);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!state || funnelStart !== "tier_change") return;

    const email = new URLSearchParams(window.location.search).get("email");

    axios
      .post(
        LAMBDA_MEMBER_TIER_CHANGED_URL,
        {
          member_email: email,
          member_new_price_id: state.price,
          member_new_tier: state.tier,
        },
        {
          headers: {
            signature: `${signature}`,
          },
        }
      )
      .finally(() => {
        redirect({
          stripeId: state.price,
          tier_type: state.tier,
          checkoutUrl: paymentUrls[state.price],
          featureFlag: rx1507PrefetchPaymentPagesFromClient,
          postgres_plan_id: state.postgres_plan_id,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, paymentUrls]);

  useEffect(() => {
    if (!state || funnelStart === "tier_change" || !originalPrice) return;

    const initialPriceAmount = originalPrice;

    redirect({
      initialPriceAmount,
      stripeId: state.price,
      tier_type: state.tier,
      checkoutUrl: paymentUrls[state.price],
      featureFlag: rx1507PrefetchPaymentPagesFromClient,
      postgres_plan_id: state.postgres_plan_id,
      percent_off: coupon?.percent_off,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, paymentUrls, rx1507PrefetchPaymentPagesFromClient, originalPrice]);

  const value = {
    redirect: (params: RedirectParams) => {
      setLoading(true);
      setState(params);
    },
  };
  if (loading) return <Loading text={content?.payment_loading} />;
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};
export default LoadingProvider;
