import styles from "./styles.module.scss";
import List from "../List";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import BackButton from "../../commonComponents/BackButton";
import illustration from "../../assets/images/price_Illustration.png";
import OurRitualLogo from "../../assets/icons/OurRitualLogo";
import React from "react";

const Header = () => {
  const currentPlan = useCurrentPlan();

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${illustration})` }}
    >
      <div className={styles.logo}>
        <BackButton />
        <OurRitualLogo />
      </div>
      <List text={currentPlan.subtitle} items={currentPlan.items.split("\n")} />
    </div>
  );
};

export default Header;
