import { Navigate } from "react-router-dom";
import Main from "../pages/Main";
import Prices from "../pages/Prices";
import SubscriptionPlan from "../pages/SubscriptionPlan";
import PaymentPlan from "../pages/PaymentPlan";
import Plans from "../pages/Plans";
import CheckoutPage from "../pages/Checkout";
import MemberCreationError from "../pages/MemberCreationError";
import UpdatePaymentDetails from "../pages/UpdatePaymentDetails";

enum PAGE_ROUTES {
  HOME = "/",
  PRICES = "/prices",
  CHECKOUT = "/checkout",
  UPDATE_PAYMENT_DETAILS = "/update-payment-details",
  MEMBER_CREATION_ERROR = "/account-creation-issue",
  NOT_FOUND = "*",
}

export const ROUTES = {
  HOME: "/" + window.location.search,
  PRICES: "/prices" + window.location.search,
  CHECKOUT: "/checkout" + window.location.search,
  UPDATE_PAYMENT_DETAILS: "/update-payment-details" + window.location.search,
  MEMBER_CREATION_ERROR: "/account-creation-issue" + window.location.search,
  NOT_FOUND: "*",
};

export const routes = [
  {
    path: PAGE_ROUTES.HOME,
    element: <Main />,
  },
  {
    path: PAGE_ROUTES.PRICES,
    element: <Prices />,
  },
  {
    path: PAGE_ROUTES.NOT_FOUND,
    element: <Navigate to={ROUTES.HOME} />,
  },
  {
    path: PAGE_ROUTES.CHECKOUT,
    element: <CheckoutPage />,
  },
  {
    path: PAGE_ROUTES.UPDATE_PAYMENT_DETAILS,
    element: <UpdatePaymentDetails />,
  },
  {
    path: PAGE_ROUTES.MEMBER_CREATION_ERROR,
    element: <MemberCreationError />,
  },
];

export enum NEW_PAGE_ROUTES {
  PLANS = "/plans",
  SELECT_INDIVIDUAL_PLAN = "/select-plan-individual",
  SELECT_COUPLE_PLAN = "/select-plan-couple",
  SELECT_PAYMENT_PLAN = "/select-payment-plan",
  CHECKOUT = "/checkout",
  UPDATE_PAYMENT_DETAILS = "/update-payment-details",
  MEMBER_CREATION_ERROR = "/account-creation-issue",
  NOT_FOUND = "*",
}

export const NEW_ROUTES = {
  PLANS: NEW_PAGE_ROUTES.PLANS + window.location.search,
  SELECT_INDIVIDUAL_PLAN:
    NEW_PAGE_ROUTES.SELECT_INDIVIDUAL_PLAN + window.location.search,
  SELECT_COUPLE_PLAN:
    NEW_PAGE_ROUTES.SELECT_COUPLE_PLAN + window.location.search,
  SELECT_PAYMENT_PLAN:
    NEW_PAGE_ROUTES.SELECT_PAYMENT_PLAN + window.location.search,
  CHECKOUT: NEW_PAGE_ROUTES.CHECKOUT + window.location.search,
  UPDATE_PAYMENT_DETAILS:
    NEW_PAGE_ROUTES.UPDATE_PAYMENT_DETAILS + window.location.search,
  MEMBER_CREATION_ERROR:
    NEW_PAGE_ROUTES.MEMBER_CREATION_ERROR + window.location.search,
  NOT_FOUND: "*",
};

export const newRoutes = [
  {
    path: NEW_PAGE_ROUTES.PLANS,
    element: <Plans />,
  },
  {
    path: NEW_PAGE_ROUTES.SELECT_INDIVIDUAL_PLAN,
    element: <SubscriptionPlan />,
  },
  {
    path: NEW_PAGE_ROUTES.SELECT_COUPLE_PLAN,
    element: <SubscriptionPlan />,
  },
  {
    path: NEW_PAGE_ROUTES.SELECT_PAYMENT_PLAN,
    element: <PaymentPlan />,
  },
  {
    path: NEW_PAGE_ROUTES.CHECKOUT,
    element: <CheckoutPage />,
  },
  {
    path: NEW_PAGE_ROUTES.UPDATE_PAYMENT_DETAILS,
    element: <UpdatePaymentDetails />,
  },
  {
    path: NEW_PAGE_ROUTES.NOT_FOUND,
    element: <Navigate to={NEW_ROUTES.SELECT_INDIVIDUAL_PLAN} />,
  },
  {
    path: NEW_PAGE_ROUTES.MEMBER_CREATION_ERROR,
    element: <MemberCreationError />,
  },
];
