import React from "react";
import styles from "./styles.module.scss";
import { Typography } from "../../components";
import PricesList from "../Prices/PricesList";
import MoneyBackBanner from "../../components/MoneyBackBanner";

const MobileLayout = () => {
  return (
    <div className={styles.wrapper}>
      <Typography variant="header" style={{ margin: "20px 0" }}>
        Choose your Plan
      </Typography>
      <PricesList />
      <MoneyBackBanner />
    </div>
  );
};

export default MobileLayout;
