export const getPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ["mobile", "android", "iphone", "ipad", "ipod"];

  if (mobileKeywords.find((key) => userAgent.includes(key))) {
    return "mobile";
  } else {
    return "desktop";
  }
};
