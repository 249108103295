import React from "react";
import Typography from "../Typography";

const Title = ({ variant }: { variant?: any }) => {
  const firstName = new URLSearchParams(window.location.search).get(
    "first_name",
  );

  return (
    <Typography
      style={{ marginTop: "10px" }}
      variant={variant || "large-header"}
    >
      {firstName && <span>{firstName},</span>} {firstName ? "h" : "H"}ere’s your
      recommended plan
    </Typography>
  );
};

export default Title;
