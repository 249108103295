import { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

interface TypographyProps extends PropsWithChildren {
  variant?: "header" | "title" | "body" | "body2";
  className?: string;
}

const componentMapping = { header: "h1", title: "h2", body: "p", body2: "p" };

const Typography = ({
  variant = "body",
  children,
  className,
}: TypographyProps) => {
  const Tag = componentMapping[variant] as any;
  return (
    <Tag className={`${styles[`typography-${variant}`]} ${className}`}>
      {children}
    </Tag>
  );
};

export default Typography;
