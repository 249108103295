import {BackendPlan, Price} from "../api/types";

export const findMatchedPlan = (
  subscription: string,
  selected: Price | null,
  backendPlans?: BackendPlan[]
): BackendPlan | undefined => {
  const subscription_number = subscription === 'Individual' ? 2 : 1;
  return backendPlans?.find(
    plan =>
      plan.billing_cycle_type === 1 &&
      plan.category === subscription_number &&
      plan.plan_month_length === selected?.months
  );
};