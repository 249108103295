import { useLocation } from "react-router-dom";
import { UnlockGrowthBanner } from "../../../components";
import MainSection from "./MainSection";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getMatchingFromSearchParams,
  getSubscriptionFromSearchParams,
} from "../../../utils/pricing-content";

const DesktopLayout = () => {
  const { state, search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const { showNewPricingFlow, matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);

  return (
    <>
      <MainSection />
      {(state?.step === 2 ||
        (subscription && showNewPricingFlow) ||
        (matchingPricing && matching)) && <UnlockGrowthBanner />}
    </>
  );
};

export default DesktopLayout;
