import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  id?: string;
}

const Button = ({ children, onClick, id }: ButtonProps) => {
  return (
    <button id={id} className={styles.btn} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
