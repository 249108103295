import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cmsUrls } from "./urls";
import {
  AllPlansDescription,
  CMSResponseType,
  DesktopStaticContent,
  Plan,
  Price,
  Review,
  Tier,
} from "./types";
import {
  CMS_URL,
  tranformPlanTitle,
  transformPlans,
} from "../utils/pricing-content";

const transformCmsResponse = (res: CMSResponseType<any, true>) => {
  return res.data.map(({ attributes, id }) => ({ ...attributes, id }));
};

const transformImage = (image: any) =>
  new URL(cmsUrl).origin + image?.data?.attributes?.url;

const cmsUrl =
  process.env.REACT_APP_CMS_URL || "https://cms.heyritual.com/api/";

export const cmsApi = createApi({
  reducerPath: "cms",
  baseQuery: fetchBaseQuery({
    baseUrl: cmsUrl,
  }),
  endpoints: (builder) => ({
    getPlans: builder.query<Plan[], void>({
      query: cmsUrls.plans,
      transformResponse: (res: CMSResponseType<Plan, true>) =>
        transformCmsResponse(res)
          .map((item) => ({
            ...item,
            image: transformImage(item?.image),
            default_tier: {
              ...item.default_tier.data.attributes,
              id: item.default_tier.data.id,
              initial_price:
                item.default_tier.data.attributes.initial_price.data.attributes
                  .stripe_id,
            },
            card_image: transformImage(item.card_image),
          }))
          .sort((a, b) => {
            const primarySelection = "Couple";
            if (a.name === primarySelection) return -1;
            else return 1;
          }),
    }),

    getPrices: builder.query<Price[], string>({
      query: cmsUrls.prices,
      transformResponse: (res: CMSResponseType<Price, true>) => {
        const params = new URLSearchParams(window.location.search);
        const coupon = params.get("coupon");
        const withCoupon = coupon && coupon !== "xxx";
        const prices: Price[] = transformCmsResponse(res)
          .filter(({ publishedAt }) => {
            if (window.location.origin.includes("dev")) return !publishedAt;
            return !!publishedAt;
          })
          .map((item) => {
            const tier = item?.tier?.data?.attributes;
            const repackagingTitle = tranformPlanTitle(item.months);
            return {
              ...item,
              title: repackagingTitle,
              tier: {
                ...tier,
                label_image: transformImage(tier.label_image),
              },
            };
          })
          .sort((a, b) => a.months - b.months);
        return withCoupon
          ? prices.filter(({ valid_with_coupon }) => valid_with_coupon)
          : prices;
      },
    }),

    getTiers: builder.query<Tier[], string>({
      query: cmsUrls.tiers,
      transformResponse: (res: CMSResponseType<Tier, true>) =>
        transformCmsResponse(res).map((item) => {
          const image = new URL(cmsUrl).origin + item.image.data.attributes.url;
          new Image().src = image; //preload
          return {
            ...item,
            image,
            initial_price: item.initial_price.data?.attributes.stripe_id,
          };
        }),
    }),
    getReviews: builder.query<Review[], void>({
      query: cmsUrls.reviews,
      transformResponse: (res: CMSResponseType<Review, true>) =>
        transformCmsResponse(res).map((reviews) => ({
          ...reviews,
          rating: CMS_URL + reviews.Rating.data?.[0]?.attributes?.url?.slice(1),
        })),
    }),

    getDesktopStaticContent: builder.query<DesktopStaticContent, void>({
      query: cmsUrls.desktopStaticContent,
      transformResponse: (res: CMSResponseType<DesktopStaticContent, false>) =>
        res.data.attributes,
    }),

    getFAQ: builder.query<any, void>({
      query: cmsUrls.faqQuestions,
      transformResponse: (res: CMSResponseType<any, true>) =>
        transformCmsResponse(res).sort((a, b) => a.order - b.order),
    }),

    getAllPlansDescription: builder.query<AllPlansDescription[], void>({
      query: cmsUrls.allPlansDescription,
      transformResponse: (res: CMSResponseType<AllPlansDescription[], true>) =>
        transformCmsResponse(res).map((plans) => transformPlans(plans)),
    }),

    getRelationshipExpertDescription: builder.query<
      AllPlansDescription[],
      void
    >({
      query: cmsUrls.relationExpertDescription,
      transformResponse: (res: CMSResponseType<Plan, true>) =>
        transformCmsResponse(res).map((plans) => transformPlans(plans)),
    }),
  }),
});

export const {
  useGetPlansQuery,
  useGetPricesQuery,
  useGetTiersQuery,
  useGetDesktopStaticContentQuery,
  useGetFAQQuery,
  useGetAllPlansDescriptionQuery,
  useGetRelationshipExpertDescriptionQuery,
  useGetReviewsQuery,
} = cmsApi;
