import { configureStore } from "@reduxjs/toolkit";
import { cmsApi } from "../api/cms";
import { stripeApi } from "../api/stripe";
import pricesReducer from "./pricesReducer";
import { managementApi } from "../api/management";
import { rtkQueryErrorLogger } from "./middlewares";

export const store = configureStore({
  reducer: {
    [cmsApi.reducerPath]: cmsApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [managementApi.reducerPath]: managementApi.reducer,
    prices: pricesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(cmsApi.middleware)
      .concat(stripeApi.middleware)
      .concat(managementApi.middleware),
});
