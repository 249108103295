import LoadingProvider from "./contexts/LoadingContext";
import { useFlags, withLDProvider } from "launchdarkly-react-client-sdk";
import OldPricingPage from "./pricingVersions/OldPricingPage";
import NewPricingPage from "./pricingVersions/NewPricingPage";
import Loading from "./components/Loading";
import { useEffect } from "react";
import { getPlatform } from "./utils/getPlatform";
import AffiliateBanner from "./components/AffiliateBanner";
import { useLocation } from "react-router-dom";
import {getMatchingFromSearchParams, getSubscriptionFromSearchParams} from "./utils/pricing-content";

function App() {
  const { showNewPricingFlow, matchingPricing } = useFlags();
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const matching = getMatchingFromSearchParams(search)

  useEffect(() => {
    if ((subscription && showNewPricingFlow) || (matching && matchingPricing)) {
      //TODO: remove it when ritualNewPricing FF will be removed
      const body = document.querySelector("body");
      if (body) body.style.background = "white";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNewPricingFlow, subscription, matching, matchingPricing]);

  if (showNewPricingFlow === undefined) return <Loading />;

  return (showNewPricingFlow && subscription) || (matching && matchingPricing) ? (
    <NewPricingPage />
  ) : (
    <OldPricingPage />
  );
}

const AppWithProvider = () => (
  <LoadingProvider>
    <AffiliateBanner />
    <App />
  </LoadingProvider>
);

const queryParams = new URLSearchParams(window.location.search);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID as string,
  context: {
    kind: "user",
    key: queryParams.get("uuid") || "NA",
    email: queryParams.get("email") || "NA",
    platform: getPlatform(),
  },
})(AppWithProvider);
