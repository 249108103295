import Typography from "../Typography";
import styles from "./styles.module.scss";

const PromoCodeDescription = () => {
  if (!window.location.search.includes("coupon")) return <></>;

  return (
    <div className={styles.wrapper}>
      <Typography variant="subtitle2" style={{ textAlign: "center" }}>
        Promo codes will be applied at checkout
      </Typography>
    </div>
  );
};

const Description = () => {
  return <PromoCodeDescription />;
};

export default Description;
