import React from "react";
import styles from "./styles.module.scss";

interface OriginalPriceProps {
  price: number;
  originalPrice: number;
}

const OriginalPrice = ({ price, originalPrice }: OriginalPriceProps) => {
  return (
    <>
      {price !== originalPrice && (
        <div className={styles.original_price}>${originalPrice}</div>
      )}
    </>
  );
};

export default OriginalPrice;
