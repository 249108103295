import BackButton from "../../commonComponents/BackButton";
import styles from "./styles.module.scss";
import OurRitualLogo from "../../assets/icons/OurRitualLogo";
import React from "react";

const DesktopHeader = () => {
  return (
    <div>
      <div className={styles.logo_wrapper}>
        <BackButton className={styles.logo} />
        <OurRitualLogo />
      </div>
      <div
        className={styles.desktop_title}
        style={{ maxWidth: "initial", whiteSpace: "break-spaces" }}
      >
        Your relationship is <span>worth it.</span>
        {"\n"}Let’s build your <span>custom plan.</span>
      </div>
    </div>
  );
};

export default DesktopHeader;
