import { createAction, createReducer } from "@reduxjs/toolkit";
import { useAppSelector } from "./hooks";

export const setActiveKey = createAction<string>("prices/set-active-months");

export interface State {
  activeKey: string;
}

const initialState: State = {
  activeKey: "",
};

const pricesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setActiveKey, (state, action) => {
    state.activeKey = action.payload;
  });
});

export function useGetPricesState() {
  return useAppSelector((state) => state.prices);
}

export default pricesReducer;
