import CheckMark from "../../assets/icons/CheckMark";
import Typography from "../Typography";
import styles from "./styles.module.scss";

interface ListProps {
  text: string;
  items: string[];
}

const List = ({ text, items }: ListProps) => {
  return (
    <div className={styles.wrapper}>
      <Typography>{text}</Typography>
      <ul className={styles.list}>
        {items.map((item) => (
          <li key={item}>
            <CheckMark />
            <Typography>{item}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
