import React, { useContext, useState } from "react";
import styles from "../styles.module.scss";
import { Button, Typography } from "../../../../components";
import useIsDesktopMode from "../../../../hooks/useIsDesktopMode";
import { useLazyGetCouponQuery } from "../../../../api/management";
import { CouponContext } from "../../../../contexts/CouponContext";
import CloseIcon from "../../../../assets/icons/Close";
import { useLocation } from "react-router-dom";
import { useGetPriceQuery } from "../../../../api/stripe";
import { getPriceFromQuery } from "../../../../utils/getCustomerData";

const PromotionCode = () => {
  const [showInput, setShowInput] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const isDesktopMode = useIsDesktopMode();
  const [getCoupon, { data: couponData, isError }] = useLazyGetCouponQuery();
  const { coupon, setCoupon } = useContext(CouponContext);
  const { state } = useLocation();
  const { data: price } = useGetPriceQuery(state?.price || getPriceFromQuery());
  const [isCouponLoading, setIsCouponLoading] = useState(false);

  const handleClick = async () => {
    setIsCouponLoading(true);
    if (
      couponData &&
      couponData.name.toLowerCase() === couponValue.toLowerCase()
    ) {
      setCoupon({
        name: couponValue,
        percentOff: couponData.percent_off,
        id: couponData.id,
        amount_off: couponData.amount_off,
      });
    } else if (couponValue) {
      try {
        const response = await getCoupon(couponValue).unwrap();
        setCoupon({
          name: couponValue,
          percentOff: response.percent_off,
          id: response.id,
          amount_off: response.amount_off,
        });
        setShowInput(!showInput);
      } catch (e) {
        setIsCouponLoading(false);
        return;
      }
    }

    setIsCouponLoading(false);

    !showInput && setShowInput(!showInput);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponValue(event.target.value);
  };

  const onCouponCancel = () => {
    setCoupon(undefined);
    setShowInput(true);
  };

  return (
    <>
      {coupon ? (
        <>
          <div className={styles.discountWrapper}>
            <div className={styles.coupon}>
              <Typography
                variant="subtitle"
                style={{ fontWeight: 600, color: "black" }}
              >
                {coupon?.name}
              </Typography>
              <div onClick={onCouponCancel} className={styles.closeIcon}>
                <CloseIcon />
              </div>
            </div>
            <Typography variant="subtitle" style={{ marginTop: "20px" }}>
              -
              {(coupon?.percentOff
                ? price * coupon?.percentOff
                : coupon?.amount_off || 0) / 100}{" "}
              $
            </Typography>
          </div>
          <Typography variant="subtitle">
            {coupon.percentOff
              ? `${coupon.percentOff}%`
              : `${(coupon?.amount_off || 0) / 100}$`}{" "}
            discount
          </Typography>
        </>
      ) : (
        <>
          <div
            className={styles.inputWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            {showInput && (
              <input
                type="text"
                className={styles.input}
                autoFocus
                placeholder="Enter promotion code"
                onChange={handleInputChange}
              />
            )}

            <Button
              style={{
                backgroundColor: "#ffff",
                color: "#1F2222",
                width: showInput ? "100%" : "fit-content",
                borderRadius: "5px",
                marginTop: isDesktopMode ? "20px" : "10px",
                padding: "12px",
              }}
              onClick={handleClick}
            >
              {isCouponLoading ? "Loading..." : "Add promotion code"}
            </Button>
          </div>
          {isError && (
            <Typography variant="subtitle" style={{ color: "red" }}>
              Code is not valid.
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default PromotionCode;
