import React from "react";
import BackButton from "../../commonComponents/BackButton";
import styles from "./styles.module.scss";
import OurRitualLogo from "../../assets/icons/OurRitualLogo";

const Header = () => {
  return (
    <div className={styles.header}>
      <BackButton />
      <OurRitualLogo />
    </div>
  );
};

export default Header;
