import React from "react";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import DesktopLayout from "../Main/DesktopLayout";
import MobileLayout from "./MobileLayout";

const Plans = () => {
  const isDesktopMode = useIsDesktopMode();

  return isDesktopMode ? <DesktopLayout /> : <MobileLayout />;
};

export default Plans;
