import { useMemo } from "react";
import { useGetPricesQuery } from "./cms";
import { Price } from "./types";
import { useGetCouponQuery } from "./management";

export const useGetCouponResolver = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const couponName = queryParams.get("coupon") || "";

  return useGetCouponQuery(couponName, { skip: !couponName });
};

export const useMappedByMonthsPrices = () => {
  const { data: prices } = useGetPricesQuery("");

  return useMemo(() => {
    const pricesByMonths: { [key: string]: Price[] } = {};

    prices?.forEach((element) => {
      const existingArray = pricesByMonths[element.title] || [];
      pricesByMonths[element.title] = [...existingArray, element];
    });

    return pricesByMonths;
  }, [prices]);
};
