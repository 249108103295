import React from "react";
import Back from "../../assets/icons/Back";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import OurRitualLogo from "../../assets/icons/OurRitualLogo";

const BackButton = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <Back />
      <OurRitualLogo />
    </div>
  );
};

export default BackButton;
