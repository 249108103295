import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";
import { priceUrls } from "./urls";
import { getMatchingFromSearchParams } from "../utils/pricing-content";
import { useLocation } from "react-router-dom";
import useCurrentTier from "../hooks/useCurrentTier";
import { useFlags } from "launchdarkly-react-client-sdk";

const stripeUrl =
  process.env.REACT_APP_STRIPE_URL || "https://api.stripe.com/v1/";

const stripeApiKey =
  process.env.REACT_APP_STRIPE_KEY ||
  "rk_test_51KG0VADJtTREqYymJKET6f5GVM9jlPLhNrbLFMF8JU1EtDuftlsM2JSO4RZr54f61ksMh6pAz0kWbhEddx2F5Q7M00eFkpJozp";

export const stripeApi = createApi({
  reducerPath: "stripe",
  baseQuery: fetchBaseQuery({
    baseUrl: stripeUrl,
    prepareHeaders: async (headers) => {
      headers.set("Authorization", `Bearer ${stripeApiKey}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPrices: builder.query<
      {
        data: {
          unit_amount: number;
          id: string;
          recurring: { interval_count: number };
        }[];
      },
      void
    >({
      query: priceUrls.prices,
    }),
  }),
});

export const { useGetPricesQuery } = stripeApi;

export const useGetPriceQuery = (id: string) => {
  const { data } = useGetPricesQuery();
  const { price, originalPrice } = useMemo(() => {
    const unparsedPrice = data?.data?.find((item) => item.id === id);
    const unit_amount = unparsedPrice?.unit_amount;
    const originalPrice = unit_amount ? unit_amount / 100 : 0;
    let price = originalPrice;
    return {
      price,
      originalPrice,
    };
  }, [data, id]);

  return { data: price, originalPrice };
};

export const useGetMostExpensivePriceQuery = (ids: string[]) => {
  const { search, state } = useLocation();
  const matching = getMatchingFromSearchParams(search);
  const { matchingPricing } = useFlags();
  let tier = useCurrentTier();

  if (matching && matchingPricing) {
    tier = state?.tier;
  }

  const { data: allPrices } = useGetPricesQuery(tier?.id, { skip: !tier });
  const prices = allPrices?.data.filter((item) => ids.includes(item.id));

  if (matching && matchingPricing && prices && prices.length > 1) {
    prices?.shift();
  }

  const mostExpensive = prices?.reduce((max, price) => {
    return price.unit_amount > max ? price.unit_amount : max;
  }, 0);

  return (mostExpensive || 0) / 100;
};
