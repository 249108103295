import {
  PAYMENT_ERROR_MESSAGES,
  REACT_APP_CHECKOUT_COMPLETE_URL,
} from "../../../utils/pricing-content";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import styles from "../styles.module.scss";
import Typography from "../../../components/Typography";
import PaymentDetailsForm from "../../../components/PaymentDetailsForm";
import { PaymentType } from "../../../utils/payment";
import { useUpdatePaymentDetailsMutation } from "../../../api/management";
import {
  getCustomerData,
  getPriceFromQuery,
} from "../../../utils/getCustomerData";
import { useLocation } from "react-router-dom";
import { useGetPriceQuery } from "../../../api/stripe";

const UpdatePaymentDetailsForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [updatePaymentDetails] = useUpdatePaymentDetailsMutation();
  const { state } = useLocation();
  const { originalPrice } = useGetPriceQuery(
    state?.price || getPriceFromQuery(),
  );

  const handleError = ({
    error,
    status_code,
  }: {
    error?: string;
    status_code?: number;
  }) => {
    setLoading(false);
    const errorMessage = error || PAYMENT_ERROR_MESSAGES[status_code ?? 500];
    setError(errorMessage);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!name) {
      setError("Name field is required");
      return;
    }

    setError("");
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError({ error: submitError.message });
      return;
    }

    const customerData = getCustomerData({
      initialAmount: originalPrice,
      postgres_plan_id: state?.postgres_plan_id,
      tier_type: state?.tier,
      price: state?.price,
    });

    const { error: paymentMethodCreationError, paymentMethod } =
      await stripe.createPaymentMethod({
        elements,
        params: { billing_details: { name } },
      });

    if (paymentMethodCreationError) {
      handleError({});
      setLoading(false);
    } else {
      try {
        await updatePaymentDetails({
          payment_token: paymentMethod?.id,
          ...customerData,
        }).unwrap();
        window.location.href =
          REACT_APP_CHECKOUT_COMPLETE_URL + window.location.search;
      } catch (e: any) {
        handleError({ status_code: e?.status_code });
      }
    }
    setLoading(false);
  };

  return (
    <div className={styles.form_wrapper}>
      <Typography
        variant="title"
        style={{ fontSize: "20px", marginBottom: "10px" }}
      >
        Enter payment details
      </Typography>
      <PaymentDetailsForm
        handleSubmit={handleSubmit}
        error={error}
        name={name}
        onNameChange={(e) => setName(e.target.value)}
        isLoading={isLoading}
        type={PaymentType.UpdatePaymentDetails}
      />
    </div>
  );
};

export default UpdatePaymentDetailsForm;
