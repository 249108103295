import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { managementUrls } from "./urls";
import {
  Coupon,
  Member,
  Payment,
  PaymentResponse,
  BackendPlan,
  UpdatePaymentDetailsPayload,
} from "./types";

const managementUrl =
  process.env.REACT_APP_API_URL ||
  "https://ritualx.ritual-app.co/backend/v1/";

export const managementApi = createApi({
  reducerPath: "management",
  baseQuery: fetchBaseQuery({
    baseUrl: managementUrl,
    prepareHeaders: async (headers) => {
      headers.set("x-ritual-source", "pricing-page");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCoupon: builder.query<Coupon, string>({
      query: managementUrls.coupon,
    }),
    getBackendPlans: builder.query<BackendPlan[], void>({
      query: () => managementUrls.fetchPlansDetails,
    }),
    createPayment: builder.mutation<PaymentResponse, Payment>({
      query: (body) => ({
        url: managementUrls.createPayment,
        method: "POST",
        body,
      }),
    }),
    createMember: builder.mutation<any, Member>({
      query: (body) => ({
        url: managementUrls.createMember,
        method: "POST",
        body,
      }),
    }),
    updatePaymentDetails: builder.mutation<
      boolean,
      UpdatePaymentDetailsPayload
    >({
      query: (body) => ({
        url: managementUrls.updatePaymentDetails,
        method: "POST",
        body,
      }),
    }),
    getNeedToUpdatePaymentDetails: builder.query<
      Coupon,
      { email: string; price: string }
    >({
      query: managementUrls.checkNeedToUpdatePaymentDetails,
    }),
  }),
});

export const {
  useGetCouponQuery,
  useLazyGetCouponQuery,
  useCreatePaymentMutation,
  useCreateMemberMutation,
  useUpdatePaymentDetailsMutation,
  useLazyGetNeedToUpdatePaymentDetailsQuery,
  useGetBackendPlansQuery,
} = managementApi;
