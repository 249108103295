import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface TypographyProps {
  variant?:
    | "header"
    | "title"
    | "subtitle"
    | "price"
    | "large-header"
    | "subtitle2";
  children: ReactNode;
  style?: any;
}

const componentMapping = {
  header: "h1",
  "large-header": "h1",
  title: "h2",
  subtitle: "p",
  subtitle2: "p",
  price: "h3",
};

const Typography = ({ variant = "subtitle", children, ...rest }: TypographyProps) => {
  const Tag = componentMapping[variant] as any;
  return <Tag {...rest} className={styles[`typography-${variant}`]}>{children}</Tag>;
};

export default Typography;
