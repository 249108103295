import React, { ReactNode, useContext } from "react";
import styles from "../styles.module.scss";
import { Typography } from "../../../../components";
import useIsDesktopMode from "../../../../hooks/useIsDesktopMode";
import PromotionCode from "../PromotionCode";
import useCurrentPlan, {
  Subscriptions,
} from "../../../../hooks/useCurrentPlan";
import { useLocation } from "react-router-dom";
import { useGetPriceQuery } from "../../../../api/stripe";
import { CouponContext } from "../../../../contexts/CouponContext";
import {
  getMatchingFromSearchParams,
  getPlanTitle,
  getPriceWithCoupon,
} from "../../../../utils/pricing-content";
import {getNewBillingCycleFromQuery, getPriceFromQuery} from "../../../../utils/getCustomerData";
import { useFlags } from "launchdarkly-react-client-sdk";

const PriceText = ({ children }: { children: ReactNode }) => (
  <Typography
    variant="subtitle"
    style={{
      fontWeight: 600,
      color: "#1F2222",
    }}
  >
    {children}
  </Typography>
);

const PriceDetails = ({ showPrice }: { showPrice: boolean }) => {
  const isDesktopMode = useIsDesktopMode();
  const plan = useCurrentPlan();
  const { state, search } = useLocation();
  const { data: price } = useGetPriceQuery(state?.price || getPriceFromQuery());
  const { coupon } = useContext(CouponContext);
  const billingCycle = getNewBillingCycleFromQuery();
  const { matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const priceWithCoupon = getPriceWithCoupon(price, coupon);
  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode");

  return (
    <div className={styles.detailsWrapper}>
      {!showPrice && (
        <div className={styles.price}>
          <Typography
            variant="large-header"
            style={{ margin: 0, width: "fit-content", fontWeight: 600 }}
          >
            {billingCycle ? `$${priceWithCoupon/4}` : `$${priceWithCoupon}`}
          </Typography>
          {!(matching && matchingPricing) &&
            plan?.name !== Subscriptions.Matching && (
              <Typography
                variant="subtitle"
                style={{
                  fontWeight: 600,
                  margin: isDesktopMode ? "auto" : "auto 0",
                  marginLeft: isDesktopMode ? "10px" : "5px",
                  color: "#575961",
                }}
              >
                {billingCycle ? "per week" : "per month"}
              </Typography>
            )}
        </div>
      )}

      {(isDesktopMode || showPrice) && (
        <>
          <div className={styles.details}>
            <PriceText>
              Ritual -{" "}
              {state
                ? getPlanTitle(state?.months, plan?.name, state?.frequency)
                : "Matching Session"}
            </PriceText>
            <PriceText>${price}</PriceText>
          </div>
          {!(matching && matchingPricing) &&
            plan?.name !== Subscriptions.Matching && (
              <Typography
                variant="subtitle"
                style={{ fontSize: "14px", marginTop: "5px" }}
              >
                {billingCycle ? "Billed every 4 weeks" : "Billed monthly"}
              </Typography>
            )}

          <hr className={styles.divider} />

          <div className={styles.details}>
            <PriceText>Subtotal</PriceText>
            <PriceText>${price}</PriceText>
          </div>

          {!mode && <PromotionCode />}

          <hr className={styles.divider} />

          <div className={styles.details}>
            <PriceText>Total due today</PriceText>
            <PriceText>${priceWithCoupon}</PriceText>
          </div>
        </>
      )}
    </div>
  );
};

export default PriceDetails;
